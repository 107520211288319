/* eslint-disable camelcase */
import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import {
  BreadCrumbs,
  PageLayout,
  SocialShare,
  PageHeading,
  LocationCard,
  // DietitianBookingsModal,
  EnquireFormModal,
} from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Trainer: FC = ({ data }: any) => {
  const {
    name,
    description,
    content,
    image,
    slug,
    locations,
    ctaLink,
    personalTrainersContent,
    personalTrainersTab,
    seo,
  } = data.strapiPersonalTrainers;

  const { banner } = data.strapiServicePage;
  const [TrainerModalOpen, setTrainerModalOpen] = useState(false);
  const handleTrainerModalOpen = () => {
    setTrainerModalOpen(true);
  };
  const trainerImage =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  const breadCrumb = [
    { title: 'Home', slug: '/' },
    {
      title: 'Fitness',
      slug: `/${data.strapiServicesPage.slug}/fitness`,
    },
    {
      title: `${data.strapiPersonalTrainers.name}`,
      slug: `/${data.strapiServicesPage.slug}/fitness/${slug}`,
    },
  ];
  const [Tab, selectTab] = useState(0);
  return (
    <PageLayout
      title={name}
      isMainBannerNav={false}
      metaTitle={seo?.metaTitle ?? name}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      dealTitle="Fitness Page"
    >
      <div className="container">
        <section className="dietition">
          <BreadCrumbs breadCrumb={breadCrumb} />
          <div className="trainer_heading">
            <h1>{name}</h1>
            <h3 className="trainer_consulting">Personal Trainer</h3>
          </div>
          <div className="trainer_wrapper">
            <div className="trainer_image">
              <GatsbyImage image={trainerImage} alt={name} />
              <div className="trainer_shares">
                <SocialShare title={name} image={image?.localFile?.publicURL} />
              </div>
              <div className="dietition_tabs">
                {personalTrainersTab.length > 0 ? (
                  <>
                    <div className="dietition_tab">
                      <ul>
                        {personalTrainersTab.map((item: any, index: number) => {
                          return (
                            <li
                              className={`dietition_tab-title ${
                                Tab === index ? 'active' : ''
                              }`}
                              key={item.title}
                            >
                              <button
                                type="button"
                                onClick={() => selectTab(index)}
                              >
                                <span>
                                  <ReactMarkdown className="md-html">
                                    {item.title}
                                  </ReactMarkdown>
                                </span>
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="dietition_tab-content">
                      {personalTrainersTab.map((items: any, index: number) => {
                        return Tab === index ? (
                          <div
                            className="dietition_tab-content-description"
                            key={items.description}
                          >
                            <ReactMarkdown className="md-html">
                              {items.description}
                            </ReactMarkdown>
                          </div>
                        ) : (
                          ''
                        );
                      })}
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <div className="trainer_info">
              <ReactMarkdown className="trainer_summary">
                {description}
              </ReactMarkdown>
              <div className="trainer_summary_description">
                <ReactMarkdown className="md-html">{content}</ReactMarkdown>
              </div>
              <div className="btn-link-wrapper">
                {ctaLink?.bookTrainerButtonText && (
                  <>
                    <button
                      className="btn-diet-book"
                      type="button"
                      onClick={handleTrainerModalOpen}
                    >
                      <span>{ctaLink?.bookTrainerButtonText}</span>
                    </button>
                    <EnquireFormModal
                      isEnquireModalOpen={TrainerModalOpen}
                      setisEnquireModalOpen={setTrainerModalOpen}
                      contactFormModalTitle={
                        ctaLink?.popupTitle ?? `Enquire now - ${name}`
                      }
                      optionalMessage
                      trainer={name}
                      dealTitle={name}
                    />
                  </>
                )}
                {ctaLink.chatWithTrainerButtonLink && (
                  <a
                    href={`tel:${ctaLink.chatWithTrainerButtonLink}`}
                    className="btn-diet-book"
                  >
                    <span>{ctaLink.chatWithTrainerButtonText}</span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>
        <section>
          <PageHeading
            title={personalTrainersContent?.title || ''}
            subtitle={personalTrainersContent?.tagline || ''}
            description={personalTrainersContent?.content || ''}
          />

          <div className="trainer_cards">
            <div className="dietetics-service">
              {locations?.map((item: any) => {
                return <LocationCard location={item} />;
              })}
            </div>
          </div>
        </section>
      </div>
    </PageLayout>
  );
};
export default Trainer;

export const query = graphql`
  query TrainerPage($slug: String) {
    strapiPersonalTrainers(slug: { eq: $slug }) {
      description
      content
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      personalTrainersTab {
        title
        id
        description
      }
      personalTrainersContent {
        title
        tagline
        content
      }
      slug
      name
      locations {
        slug
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, height: 600)
            }
          }
        }
      }
      ctaLink {
        bookTrainerButtonText
        popupTitle
      }
    }
    strapiServicePage(slug: { eq: "fitness" }) {
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    strapiServicesPage {
      title
      slug
    }
  }
`;
